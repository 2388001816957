import React from 'react';
import './Services.scss';
import ServicesSlider from './ServicesSlider';
import Downloads from '../Downloads/Downloads';
import OurClientsT from '../OurClients/OurClients';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';


class Services extends React.Component {
  state = {
    selectedPicture: 1,
  }

  componentDidMount() {
    window.scrollTo(0,0);
  }

  nextPic() {
    if (this.state.selectedPicture < 5) {
      this.setState(state => ({selectedPicture: state.selectedPicture + 1}));
    }
  }

  prevPic() {
    if (this.state.selectedPicture > 1) {
      this.setState(state => ({selectedPicture: state.selectedPicture - 1}));
    }
  }

  render() {
    const { t } = this.props;

    let dataNew = [
      {
        title: 'Функціонал абонементів',
        subtitle: t('subscription.card-1.title'),
        description: t('subscription.card-1.sub-title'),
      },
      {
        title: 'Функціонал абонементів',
        subtitle: t('subscription.card-2.title'),
        description: t('subscription.card-2.sub-title'),
      },
      {
        title: 'Функціонал абонементів',
        subtitle: t('subscription.card-3.title'),
        description: t('subscription.card-3.sub-title'),
      },
      {
        title: 'Функціонал абонементів',
        subtitle: t('subscription.card-4.title'),
        description: t('subscription.card-4.sub-title'),
      },
      {
        title: 'Функціонал абонементів',
        subtitle: t('subscription.card-5.title'),
        description: t('subscription.card-5.sub-title'),
      },
    ];
    return(
      <div className="services-page">
        <div className="services-page__intro">
          <div className="flex-container">
            <div className="services-block">
              <div className="text-block">
                <div className="line"></div>
                <div className="title">{t('intro.title')}</div>
                <div className="main-text">
                  {t('intro.main-text')}
                </div>
              </div>
              <div className="phone"></div>
            </div>
          </div>
        </div>
        <div className="services-page__advantages">
          <div className={classNames("flex-container", `card-${this.state.selectedPicture}`)}>
            <div className="intro-container">
              <div className="line"></div>
              <div className="title">{t('subscription.title-1')}<br className='br'/> {t('subscription.title-2')}</div>
            </div>
          <div className='parent'>
            <div className="advantages--container">
            </div>
            <ServicesSlider nextPic={this.nextPic.bind(this)} prevPic={this.prevPic.bind(this)}>
              {dataNew.map(item => {
                return (
                  <div className='child'>
                    <div className="advantages-container">
                      <div className="sub-title">{item.subtitle}</div>
                      <div className="description">{item.description}</div>
                    </div>
                  </div>
                );
              })}
            </ServicesSlider>
          </div>
          </div>
        </div>
        <div className="services-page__cards">
          <div className='flex-container'>
            <div className="line"></div>
            <div className="title">{t('cards.title')}</div>
            <div className="sub-title">{t('cards.sub-title')}</div>
            <div className="grid-container">
              <div className="cards-description cards-grid">
                <div className="grid-item">
                  {t('cards.card-1')}
                  <div className="grid-number">
                    1
                  </div>
                </div>
                <div className="grid-item">
                  {t('cards.card-2')}
                  <div className="grid-number">
                    2
                  </div>
                </div>
                <div className="grid-item">
                  {t('cards.card-3')}
                  <div className="grid-number">
                    3
                  </div>
                </div>
                <div className="grid-item">
                  {t('cards.card-4')}
                  <div className="grid-number">
                    4
                  </div>
                </div>
              </div>
              <div className="cards-images cards-grid">
                <div className="grid-item item-1"></div>
                <div className="grid-item item-2"></div>
                <div className="grid-item item-3"></div>
                <div className="grid-item item-4"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="services-page__fuel">
          <div className="flex-container">
            <div className="line"></div>
            <div className="title">{t('fuel.title-1')}<br/> {t('fuel.title-2')} <br/> {t('fuel.title-3')}</div>
            <div className="mobile-photo">     
            </div>
            <div className="icon"></div>
            <div className="sub-title">{t('fuel.sub-title-1')}</div>
            <div className="main-text">{t('fuel.main-text-1')}</div>
            <div className="icon icon-2"></div>
            <div className="sub-title">{t('fuel.sub-title-1')}</div>
            <div className="main-text">{t('fuel.main-text-2')}</div>
          </div>
        </div>
        <div className="services-page__else">
        <div className="flex-container">
          <div className="line"></div>
            <div className="title">{t('functionality.title')}</div>
            <div className="grid-container">
              <div className="advantage-card card-1">
                <div className="line"></div>
                <div className="title">{t('functionality.card-1.title')}</div>
                <div className="main-text">{t('functionality.card-1.main-text')}</div>
              </div>
              <div className="advantage-card card-2">
                <div className="line"></div>
                <div className="title">{t('functionality.card-2.title')}</div>
                <div className="main-text">{t('functionality.card-2.main-text')}</div>
              </div>
              <div className="advantage-card card-3">
                <div className="line"></div>
                <div className="title">{t('functionality.card-3.title')}</div>
                <div className="main-text">{t('functionality.card-3.main-text')}</div>
              </div>
              <div className="advantage-card card-4">
                <div className="line"></div>
                <div className="title">{t('functionality.card-4.title')}</div>
                <div className="main-text">{t('functionality.card-4.main-text')}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="services-page__downloads">
          <div className="flex-container">
            <Downloads eventTracker={this.props.eventTracker.bind(this)}/>
          </div>
        </div>
        <div className="services-page__news">
          <div className="flex-container">
            <div className="line"></div>
            <div className="title">{t('news.title')}</div>
            <div className="article-block">
              <div className="article-block__content">
                <div className="content__image"></div>
                <div className="content__text">{t('news.main-text')}</div>
              </div>
              <div
                className="article-block__button"
                onClick={() => window.open('https://focus.ua/ukraine/501375-innovaciji-ta-progresivne-maybutnye-dlya-kozhnogo-ukrajincya-urbanist-vidchinyaye-dveri-zruchnosti-de-vse-zhittya-bude-skoncentrovano-v-odnomu-dodatku','_blank')}
              >
                {t('news.button')}
              </div>
            </div>
          </div>
        </div>
        <OurClientsT />
      </div>
    )
  }
}

const ServicesT = withTranslation('services')(Services);
export default ServicesT;